import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { inject, observer } from 'mobx-react'
import services from '../services'
import { InlineLoader } from '../../components/loaders'
import addLog from '../../components/analytics/utils'

const OAuth = inject('store')(
  observer((props) => {
    const [isLoading, setLoading] = useState(true)
    const { store } = props
    const router = useRouter()
    useEffect(() => {
      const setup = async () => {
        const localStorage =
          typeof window !== 'undefined' && window.localStorage
        const query = router.query || {}
        if (query.next) {
          localStorage.setItem('next', query.next)
        }
        store.setActionLoader(true)
        if (query.code) {
          try {
            await props.store.userOidcAuth(query.code, query.state, router)
          } catch (e) {
            store.setActionLoader()
            setLoading(false)
            // window.location.href = '/'
          }
        } else {
          const res = await services.get_v2('auth/login', {
            params: {
              mode: props.mode,
              connection: router.query.connection
            }
          })
          if (res.statusCode === 200 && res.status === 'success') {
            if (typeof window !== 'undefined') {
              window.location.href = res.response.authorizeUrl
            }
          } else {
            store.setActionLoader()
            setLoading(false)
          }
        }
      }
      setup()
    }, [])

    return (
      <div className="sso-container">
        <InlineLoader />
      </div>
    )
  })
)

OAuth.propTypes = {
  router: PropTypes.object,
  store: PropTypes.object,
  mode: PropTypes.string
}

export default OAuth
