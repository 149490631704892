import React from 'react'
import OidcAuthenticate from '/components/auth/OidcAuthenticate'
import DefaultLayout from '/components/layouts/DefaultLayout'
import { withAuthConditions } from '/components/utils'

const Login = () => {
  return <OidcAuthenticate mode={'login'} />
}

Login.Layout = DefaultLayout

export const getServerSideProps = withAuthConditions(
  (ctx, siteData) => {
    return {
      props: {
        siteData
      }
    }
  },
  'auth0',
  'login'
)

export default Login
